import React, { useState, useEffect } from 'react'
import { trimSpace } from '../../_util/text'
import DropDownList from '../../general/menus/DropDownList/DropDownList'
import PropTypes from 'prop-types'
import Icon from '../../general/icons/Icon'
import { withTranslation } from 'react-i18next'
import { rubricDropdownProperties } from '../../../rubricDropdownProperty'
import { categoryRegexGenerator, getCategoryState } from '../common/rubric-categorylink'
const RubricCategory = ({
  onGridCellChange,
  gridCellEditFormHeader,
  selectedRowIndex,
  rubricObject,
  parentProps,
  peerRubricEnable,
  editable,
  t
}) => {
  // character limit for custom category text
  const characterLimit = 500
  // state to handle input text visibility
  const [selectedOption, setSelectedOption] = useState('Custom category name')
  // state to handle displaying the text field for custom category
  const [customCategoryTextEnabled, setCustomCategoryTextEnabled] = useState(false)
  // error message true false state
  const [isError, setError] = useState(false)
  // error message to display
  const errorMessage = 'Character limit exceeded'
  // state to change placeholder on demand
  const [placeholderText, setPlaceholderText] = useState('')

  var listOfOptions = []

  useEffect(() => {
    var remainingWeight = 100

    rubricObject.criterias.filter((row, rowIndex) => {
      if (rowIndex !== selectedRowIndex) {
        remainingWeight -= row.weight
      }
    })

    // This is to avoid the issues there in float value simplification in Javascript
    remainingWeight = parseFloat(remainingWeight.toFixed(2))

    // Appending the none existing value
    const brokenValues = [remainingWeight]

    for (var i = 0; i <= 100; i += 5) {
      brokenValues.filter(noneExistingValue => {
        if (noneExistingValue >= 0 && noneExistingValue < i) {
          listOfOptions.push({
            value: i,
            isDisabled: i > remainingWeight,
            display: i + '%'
          })
        } else {
          listOfOptions.push({
            value: i,
            isDisabled: i > remainingWeight,
            display: i + '%'
          })
        }
      })
    }
  })
  const onGetSelectedOptionCallback = selectOption => {
    if (rubricDropdownProperties[0].value === selectOption) {
      setSelectedOption(selectOption)
      setPlaceholderText(selectOption)
      rubricObject.criterias[selectedRowIndex].title = '' // to avoid the value from overiding the placeholder
    } else {
      onGridCellChange(parentProps, {
        title: selectOption
      })
      setCustomCategoryTextEnabled(false)
      setError(false)
      setSelectedOption(selectOption)
      setPlaceholderText('')
    }
  }

  const onGetWeightSelectedOptionCallback = selectOption => {
    onGridCellChange(parentProps, { weight: selectOption })
  }

  /**
   * check whether the given catergory is a defined or a custom category
   * @param category
   * @returns {boolean}
   */
  const getCategoryStatus = () => {
    let category = editable ? rubricObject.criterias[selectedRowIndex].title : selectedOption
    let regexPredefinedCategoryMatch = categoryRegexGenerator()
    return getCategoryState(regexPredefinedCategoryMatch, category)
  }
  /**
   * boolean to which hold whether the custom category text field needs to be displayed
   * @returns {*|boolean}
   */
  const showCustomCategoryTextField = () => {
    return customCategoryTextEnabled || getCategoryStatus().isCustomCategory
  }

  /**
   * this will return the label to displayed in the category drop down
   * if the category is a defined category display the value of the defined category
   * else display the default category or the category created by the user
   * @returns {string}
   */
  const getDropDownLabel = () => {
    let label = ''
    if (getCategoryStatus().isCustomCategory) {
      label = rubricDropdownProperties[0].value
    } else {
      label = rubricObject.criterias[selectedRowIndex].title
    }
    return label
  }

  return (
    <div className='grid-cell-edit-form'>
      {gridCellEditFormHeader}
      <div className='ls-grid-cell-container'>
        <div className='ls-catogory-dropdowns'>
          <div className='ls-catogoryName'>
            <label
              className='pe-textLabelInput__label ls-textLable-bottom-catogory'
              htmlFor='categoryName'
            >
                {t('components.preview.edit.grid.category.title.label')}
            </label>
            <DropDownList
              id='categoryName'
              label={getDropDownLabel()}
              triggerType='box'
              isCheckboxEnabled
              onGetSelectedOption={onGetSelectedOptionCallback}
              options={rubricDropdownProperties}
            />
          </div>
          {showCustomCategoryTextField() && (
            <div className='ls-custom-category-name'>
              <label
                className='pe-textLabelInput__label ls-textLable-customCatogory'
                htmlFor='anInput'
              >
                  {t('components.preview.edit.grid.custom.category.label')}
                  </label>
              <input
                type='text'
                className='pe-textInput--basic ls-custom-category-name-input'
                id='anInput'
                value={rubricObject.criterias[selectedRowIndex].title}
                placeholder={placeholderText}
                onChange={event => {
                  // set this to true keep the text field visible
                  setCustomCategoryTextEnabled(true)
                  if (event.target.value.length > characterLimit) {
                    setError(true)
                  } else {
                    if (isError) {
                      setError(false)
                    }

                    onGridCellChange(parentProps, {
                      title: event.target.value
                    })
                  }
                }}
                onBlur={event => {
                  // set this to true keep the text field visible
                  setCustomCategoryTextEnabled(true)
                  onGridCellChange.bind(parentProps, {
                    title: trimSpace(event.target.value)
                  })
                }}
              />
            </div>
          )}
          {isError && (
            <div className='ls-error-message'>
              <span>
                <Icon
                  fileName='warning-16'
                  focusable='false'
                  aria-hidden='true'
                />
                <label>{errorMessage}</label>
              </span>
            </div>
          )}
        </div>
        {!peerRubricEnable === true && (
          <div className='ls-weightdropdown-position'>
            <label
              className='pe-textLabelInput__label ls-textLable-bottom'
              htmlFor='weightedValue'
            >
                {t('components.preview.edit.grid.category.weight.label')}
            </label>

            <DropDownList
              id='weightedValue'
              label={rubricObject.criterias[selectedRowIndex].weight}
              triggerType='box'
              boxWidth='70px'
              menuWidth='100px'
              isCheckboxEnabled
              onGetSelectedOption={onGetWeightSelectedOptionCallback}
              options={listOfOptions}
            />
          </div>
        )}
      </div>
      <div className='pe-col-12 grid-cell-edit-form-field'>
        <label
          className='pe-textLabelInput__label ls-textLable-description'
          htmlFor='multi_vlaai'
        >
            {t('components.preview.edit.grid.category.description.label')}
        </label>
        <textarea
          className='pe-multiLineText'
          id='multi_vlaai'
          cols='30'
          rows='5'
          value={rubricObject.criterias[selectedRowIndex].description}
          onChange={event => {
            onGridCellChange(parentProps, {
              description: event.target.value
            })
          }}
          onBlur={event => {
            onGridCellChange(parentProps, {
              description: trimSpace(event.target.value)
            })
          }}
        />
      </div>
    </div>
  )
}

RubricCategory.propTypes = {
  onGridCellChange: PropTypes.func,
  gridCellEditFormHeader: PropTypes.element,
  selectedRowIndex: PropTypes.number,
  localizationDictionary: PropTypes.any,
  locale: PropTypes.any,
  rubricObject: PropTypes.object,
  parentProps: PropTypes.any,
  peerRubricEnable: PropTypes.bool,
  editable: PropTypes.bool
}
export default withTranslation()(RubricCategory)
