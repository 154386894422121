import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resources from './locales'  // typescript: import * as resources from '../locales'

i18n
    .use(initReactI18next) // pass the i18n instance to react-i18next.
    .init({
        lng: 'en',
        fallbackLng: false,
        debug: true,
        basenameAsNamespace:true,
        resources,
        react: {
            wait: true
        },
        load: 'currentOnly',
    });

export default i18n;