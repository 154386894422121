import './accordion-panel.scss'
import React, {Component} from 'react'
import PropTypes from 'prop-types'

class AccordionPanel extends Component {
    constructor(props) {
        super(props)

        this.state = {
            collapsed: props.collapsed
        }

        this.toggleCollapse = this.toggleCollapse.bind(this)
    }

    toggleCollapse() {
        this.setState(prevState => ({collapsed: !prevState.collapsed}))
    }

    render() {
        return (
            <div className='ls-accordion'>
                <button className='pe-icon--btn' onClick={this.toggleCollapse}>
                    <span className='ls-accordion-title'>{this.props.title}</span>
                    {
                        this.state.collapsed ? (
                            <svg aria-hidden='true' focusable='false' className='pe-icon--dropdown-close-sm-18'>
                                <use xlinkHref='#dropdown-close-sm-18'/>
                            </svg>
                        ) : (
                            <svg aria-hidden='true' focusable='false' className='pe-icon--dropdown-open-sm-18'>
                                <use xlinkHref='#dropdown-open-sm-18'/>
                            </svg>
                        )
                    }
                </button>
                {!this.state.collapsed && (
                    <div className='ls-accordion-body'>
                        <p>{this.props.text}</p>
                    </div>)}
            </div>
        )
    }
}

AccordionPanel.propTypes = {
    collapsed: PropTypes.bool,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
}

AccordionPanel.defaultProps = {
    collapsed: true
}

export default AccordionPanel
