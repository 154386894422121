import React, {Component} from 'react'
import PropTypes from 'prop-types'
import './rubrics-peer-review.scss'
import PeerComment from '../../general/expandCollpase/peerComment'
import AccordianItem from '../../general/expandCollpase/accordianItem'
import AccordionPanel from '../../general/expandCollpase/AccordionPanel'
import CONST from '../Review/const'
import { PI_AUTH_HEADER } from '../common/auth-headers-constants'


class RubricsPeerReviewView extends Component {
    constructor(props) {
        super(props)
        this.getTableContent = this.getTableContent.bind(this)
        this.clickOnCollapse = this.clickOnCollapse.bind(this)
        this.calculateReceivedReviews = this.calculateReceivedReviews.bind(this)
        this.onClickOverallComment = this.onClickOverallComment.bind(this)
        this.onStudentClick = this.onStudentClick.bind(this)
        this.renderFinalContent = this.renderFinalContent.bind(this)
        this.genetareStudentList = this.genetareStudentList.bind(this)
        this.switchReviewsReceivedOrderBy = this.switchReviewsReceivedOrderBy.bind(this)
        this.state = {
            collapsed: true,
            collapsedIndex: 0,
            reviewsReceived: [],
            reviewsGiven: [],
            overallCommentsCollapsed: true,
            overallComments: [],
            allStudentList: this.genetareStudentList(props),
            reviewsReceivedOrderBy: this.props.role === CONST.USER_TYPE.INSTRUCTOR ? CONST.ORDER_BY.BY_USER : CONST.ORDER_BY.BY_CRITERIA
        }
    }

    componentDidMount() {
        this.props.onInit(this.props)
        this.props.i18n.changeLanguage(this.props.language)
    }

    /**
     * Generate Student List
     * @param studentDetails
     * @returns {{}}
     */
    genetareStudentList(studentDetails) {
        let allStudentList = studentDetails.UnsubmittedStudentList.concat(studentDetails.studentList)
        let studentList = {}
        allStudentList.map((element, index) => {
            let studentId = Object.keys(element)
            let studentName = `${element[studentId[0]].firstName} ${element[studentId[0]].lastName}`
            studentList[studentId] = studentName
        })
        return studentList
    }

    componentWillReceiveProps(nextProps) {
        let rubricData = nextProps.rubric
        if (nextProps.peerReviewDetails && this.props.peerReviewDetails !== nextProps.peerReviewDetails) {
            let reviewsGiven = this.arrangeDataByUser(nextProps.peerReviewDetails.given, rubricData)
            if (this.state.reviewsReceivedOrderBy === CONST.ORDER_BY.BY_CRITERIA) {
                let response = this.arrangeDataByCriteria(nextProps.peerReviewDetails.received, rubricData)
                this.setState({
                    reviewsReceived: response.criterias,
                    overallComments: response.overallComments,
                    reviewsGiven: reviewsGiven
                })
            } else {
                let response = this.arrangeDataByUser(nextProps.peerReviewDetails.received, rubricData)
                this.setState({
                    reviewsReceived: response,
                    reviewsGiven: reviewsGiven
                })
            }
        }
        if (this.props.loggedInUser !== nextProps.loggedInUser) {
            this.props.onInit(nextProps)
        }

        if (this.props.language !== props.language) this.props.i18n.changeLanguage(props.language)
    }

    /**
     * arrnage data by criteria
     * @param array
     * @param rubricData
     * @returns {{criterias: Array, overallComments: Array}}
     */
    arrangeDataByCriteria(array, rubricData) {
        let response = {
            criterias: [],
            overallComments: []
        }
        if (array.length > 0) {
            array[0].scores.map((score, index) => {
                let criteriaObj = {
                    title: score.title,
                    avgLevelIndex: '',
                    scores: []
                }
                let sumLevelIndex = 0
                let avgLevelIndex = 0

                array.map((review, increment) => {
                    const studentName = this.state.allStudentList[review.gradedBy]

                    let obj = {
                        comment: review.scores[index].comment,
                        desc: rubricData.criterias[index].items[review.scores[index].levelIndex].desc,
                        title: studentName,
                        value: review.scores[index].value,
                        quality: rubricData.cols[review.scores[index].levelIndex].title,
                        levelIndex: review.scores[index].levelIndex + 1,
                        totalLevelIndex: rubricData.cols.length,
                        overallComment: {
                            title: CONST.TEXT.OVERALL_PEER_COMMENT,
                            text: review.overallComment
                        }
                    }
                    sumLevelIndex = sumLevelIndex + (review.scores[index].levelIndex + 1)
                    avgLevelIndex = Math.round(sumLevelIndex / (increment + 1))
                    criteriaObj.scores.push(obj)
                    if (index === 0 && review.overallComment) {
                        response.overallComments.push({
                            comment: review.overallComment,
                            user: studentName
                        })
                    }
                })
                criteriaObj.totalLevelIndex = rubricData.cols.length
                criteriaObj.avgQuality = rubricData.cols[avgLevelIndex - 1].title
                criteriaObj.avgLevelIndex = avgLevelIndex
                response.criterias.push(criteriaObj)
            })
        }
        return response
    }

    /**
     * arrange data y user
     * @param array
     * @param rubricData
     * @returns {Array}
     */
    arrangeDataByUser(array, rubricData) {
        let response = []
        let studentName = CONST.TEXT.PEER
        if (array.length > 0) {
            array.map((review, index) => {
                const studentName = this.state.allStudentList[review.gradedBy] || this.state.allStudentList[review.gradedTo]
                let reviewObj = {
                    title: studentName,
                    peerSubmittedTime: review.peerSubmittedTime,
                    scoreId: review.scoreId,
                    scores: [],
                    overallComment: {
                        title: CONST.TEXT.OVERALL_PEER_COMMENT,
                        text: review.overallComment
                    }
                }

                review.scores.map((score, i) => {
                    let obj = {
                        title: score.title,
                        desc: rubricData.criterias[i].items[score.levelIndex].desc,
                        comment: score.comment,
                        levelIndex: score.levelIndex + 1,
                        quality: rubricData.cols[score.levelIndex].title,
                        totalLevelIndex: rubricData.criterias[i].items.length
                    }
                    reviewObj.scores.push(obj)
                })

                response.push(reviewObj)
            })
        }
        return response
    }

    /**
     * calculates received reviews
     * @param received
     * @param userId
     * @returns {boolean}
     */
    calculateReceivedReviews(userId) {
        let response = false
        let given = this.props.peerReviewDetails.given
        if (given && userId != null && given.length > 0) {
            given.map(function (obj) {
                if (obj.gradedTo === userId) {
                    response = true
                }
            })
            return response
        }
    }

    /**
     * Student on Click on Individual Student
     * @param studentObj
     * @param index
     * @param catergory
     */
    onStudentClick(studentObj, index, catergory, startPoint) {
        this.props.selectStudent(studentObj, index, catergory, startPoint)
    }

    /**
     * generates table content html
     * @param props
     * @returns {*}
     */
    getTableContent(props) {
        let peerReviewUserList = props.peerReviewUserList
        let summaryCount = props.peerReviewSummaryCount
        let that = this
        let loggedInUserId = props.loggedInUser
        let submittedStudentList = props.studentList
        if (submittedStudentList.length > 0) {
            let content = null
            if (peerReviewUserList && peerReviewUserList !== undefined && peerReviewUserList.length > 0) {
                content = submittedStudentList.map((element, index) => {
                    let studentId = Object.keys(element)
                    let studentName = `${element[studentId[0]].firstName} ${element[studentId[0]].lastName}`
                    let studentObject = element[studentId[0]]
                    let peerReviewUser = peerReviewUserList.find((student) => {
                        if (student.studentId === studentId[0]) {
                            return student
                        }
                    }, this)
                    return (
                        <tr>
                            <td onClick={that.onStudentClick.bind(that, studentObject, index, 'studentSubmittedList', {path: 'PeerReview'})}
                                className='rubric-peer-review-table-data'><a href="javascript:void(0)">{studentName}</a>
                            </td>
                            {props.role === CONST.USER_TYPE.INSTRUCTOR &&
                            <td
                                className='rubric-peer-review-table-data'>{peerReviewUser && peerReviewUser.given ? peerReviewUser.given : 0} of {this.props.numberOfPeerReviews}</td>
                            }
                            <td
                                className='rubric-peer-review-table-data'>{(that.calculateReceivedReviews(studentId[0]) && (props.role !== CONST.USER_TYPE.INSTRUCTOR)) ? 'Reviewed' : peerReviewUser && peerReviewUser.received || 0}</td>
                        </tr>)
                })
            } else {
                content = submittedStudentList.map(function (element, index) {
                    return (
                        <tr>
                            <td
                                onClick={that.onStudentClick.bind(that, element[Object.keys(element)[0]], index, 'studentSubmittedList', {path: 'PeerReview'})}
                                className='rubric-peer-review-table-data'>
                                {element[Object.keys(element)[0]].firstName + ' ' + element[Object.keys(element)[0]].lastName}
                            </td>
                            {props.role === CONST.USER_TYPE.INSTRUCTOR &&
                            <td
                                className='rubric-peer-review-table-data'>0 of 0</td>
                            }
                            <td className='rubric-peer-review-table-data'>{0}</td>
                        </tr>)
                })
            }
            return content
        } else {
            return (<tr>
                <td colSpan='3'>
                    <p className='rubric-peer-review-table-empty'>No one's completed the assignment yet. Check back
                        later to
                        complete peer reviews.</p>
                </td>
            </tr>)
        }
    }

    /**
     * on click on collapse
     * @param index
     */
    clickOnCollapse(index) {
        if (!this.state.collapsed && this.state.collapsedIndex === index) {
            this.setState({collapsed: true})
        } else {
            this.setState({collapsed: false, collapsedIndex: index})
        }
    }

    /**
     * on click overall comment collapse
     */
    onClickOverallComment() {
        this.setState({
            overallCommentsCollapsed: !this.state.overallCommentsCollapsed
        })
    }

    /**
     * change order by
     * @param index
     */
    switchReviewsReceivedOrderBy(index) {
        let orderBy = CONST.ORDER_BY.BY_CRITERIA
        let response = []
        if (this.state.reviewsReceivedOrderBy === CONST.ORDER_BY.BY_CRITERIA) {
            orderBy = CONST.ORDER_BY.BY_USER
            response = this.arrangeDataByUser(this.props.peerReviewDetails.received, this.props.rubric)
        } else {
            response = this.arrangeDataByCriteria(this.props.peerReviewDetails.received, this.props.rubric).criterias
        }
        this.setState({
            reviewsReceivedOrderBy: orderBy,
            reviewsReceived: response,
            collapsedIndex: index
        })
    }

    /**
     * render the received reviews according to criterias
     * @returns {any[]}
     */
    renderReviewsReceived() {
        let content = this.state.reviewsReceived.map((review, index) => {
            return (
                <AccordianItem clickOnCollapse={this.clickOnCollapse} collapsed={this.state.collapsed}
                               key={review.scoreId}
                               collapsedIndex={this.state.collapsedIndex} index={index}
                               title={review.title} average={review.avgLevelIndex}
                               total={review.totalLevelIndex} quality={review.avgQuality}
                >
                    {this.state.reviewsReceivedOrderBy === CONST.ORDER_BY.BY_CRITERIA &&
                    <p className='rubric-peer-review-accordian-content-title'>Rubric and comment</p>}
                    {review.scores.map((score, i) => {
                        return (
                            <PeerComment key={`received${i}`} peerName={score.title} index={i}
                                         levelIndex={score.levelIndex}
                                         totalLevels={score.totalLevelIndex}
                                         quality={score.quality}
                                         rubricDescription={score.desc || ''}
                                         peerComment={score.comment || ''}/>
                        )
                    })}

                    {this.state.reviewsReceivedOrderBy === CONST.ORDER_BY.BY_USER && review.overallComment.text &&
                        (
                        <AccordionPanel title={review.overallComment.title}
                                        text={review.overallComment.text}/>
                        )
                    }
                </AccordianItem>
            )
        })
        return content
    }

    /**
     * render the given reviews according to student
     * @returns {any[]}
     */
    renderReviewsGiven() {
        let content = this.state.reviewsGiven.map((review, index) => {
            return (
                <AccordianItem clickOnCollapse={this.clickOnCollapse} collapsed={this.state.collapsed}
                               key={review.scoreId}
                               collapsedIndex={this.state.collapsedIndex} index={index}
                               title={review.title}
                               submittedTime={review.peerSubmittedTime || ''}>
                    {review.scores.map((score, i) => {
                        return (
                            <PeerComment key={`given${i}`} peerName={score.title}
                                         levelIndex={score.levelIndex}
                                         totalLevels={score.totalLevelIndex}
                                         quality={score.quality || ''}
                                         rubricDescription={score.desc || ''}
                                         peerComment={score.comment || ''}/>
                        )
                    })}

                    {this.state.reviewsReceivedOrderBy === CONST.ORDER_BY.BY_USER && review.overallComment.text &&
                        (
                            <AccordionPanel title={review.overallComment.title}
                                            text={review.overallComment.text}/>
                        )
                    }
                </AccordianItem>
            )
        })
        return content
    }

    /**
     * render overall comments section
     * @returns {*}
     */
    renderOverallComments() {
        return (
            <AccordianItem clickOnCollapse={this.onClickOverallComment} collapsed={this.state.overallCommentsCollapsed}
                           collapsedIndex={1} index={1}
                           title={'Overall peer comment'}>
                {!this.state.overallCommentsCollapsed &&
                this.state.overallComments.map((comment) => {
                    return (<PeerComment peerName={comment.user || 'peer'}
                                         peerComment={comment.comment}/>
                    )
                })
                }
            </AccordianItem>
        )
    }

    /**
     * Render final content
     * @returns {*}
     */
    renderFinalContent() {
        switch (this.props.viewMode) {
            case CONST.VIEW_MODE.REVIEWS_RECEIVED:
                return (
                    <div className='rubric-peer-review-body pe-row'>
                        {(this.props.studentList.length > 0 && this.state.reviewsReceived.length > 0) ? this.renderReviewsReceived() :
                            <p className='rubric-peer-review-message'>{CONST.MESSAGES.NO_PEER_REVIEWS}</p>}
                        {(this.props.studentList.length > 0 && this.state.overallComments.length > 0 && this.state.reviewsReceivedOrderBy === CONST.ORDER_BY.BY_CRITERIA) && this.renderOverallComments()}
                    </div>
                )
            case CONST.VIEW_MODE.REVIEWS_GIVEN:
                return (
                    <div className='rubric-peer-review-body pe-row'>
                        {(this.props.studentList.length > 0 && this.state.reviewsGiven.length > 0) ? this.renderReviewsGiven() :
                            <p className='rubric-peer-review-message'>{CONST.MESSAGES.NO_PEER_REVIEWS}</p>}
                    </div>
                )
            default:
                return (
                    <div>
                        {this.props.role === CONST.USER_TYPE.STUDENT &&
                        <div className='rubric-peer-review-header pe-row'>
                            <p className='rubric-peer-review-title'>Choose {this.props.numberOfPeerReviews}&nbsp;
                                {this.props.numberOfPeerReviews > 1 ? CONST.TEXT.PEERS : CONST.TEXT.PEER} to review</p>
                            <p className='rubric-peer-review-subtitle'>{`${this.props.peerReviewDetails ? this.props.peerReviewDetails.given.length : 0} of ${this.props.numberOfPeerReviews} reviewed`}</p>
                        </div>
                        }
                        <div className='rubric-peer-review-body pe-row'>
                            <table className='pe-table'>
                                <thead>
                                <tr>
                                    <th scope='col'><p className='rubric-peer-review-table-header-data'>Name</p></th>
                                    {this.props.role === CONST.USER_TYPE.INSTRUCTOR &&
                                    <th scope='col'><p className='rubric-peer-review-table-header-data'>Reviews
                                        given</p>
                                    </th>
                                    }
                                    <th scope='col'><p className='rubric-peer-review-table-header-data'>Reviews
                                        received</p>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.getTableContent(this.props)}
                                </tbody>
                            </table>
                        </div>
                        {((this.props.studentList.length > 0 && ((this.state.reviewsReceived && this.state.reviewsReceived.length > 0) || (this.state.overallComments && this.state.overallComments.length > 0))) && this.props.role !== CONST.USER_TYPE.INSTRUCTOR) &&
                        <div className='rubric-peer-review-body rubric-peer-review-body-border pe-row'>
                            <p className='rubric-peer-review-body-title'>Reviews by peers</p>
                            {(this.props.studentList.length > 0) && this.renderReviewsReceived()}
                            {(this.props.studentList.length > 0 && this.state.overallComments.length > 0) && this.renderOverallComments()}
                        </div>}
                    </div>
                )
        }
    }

    render() {
        return (
            <div className='webcomponents rubric-peer-review pe-container pe-col-4'>
                {this.renderFinalContent()}
            </div>
        )
    }
}

RubricsPeerReviewView.propTypes = {
    language: PropTypes.string,
}

RubricsPeerReviewView.defaultProps = {
    viewMode: '',
    authType:PI_AUTH_HEADER,
    language: 'en'

}
export default RubricsPeerReviewView
