import React from 'react'
import PropTypes from 'prop-types'

class Tab extends React.Component {
  componentDidUpdate (prevProps) {
    if (this.props.selected && this.props.selected !== prevProps.selected) {
      this.tabLink.focus()
      this.props.onSelect()
    }
  }

  render () {
    return (
      <li
        role='tab'
        className={(this.props.selected ? 'active' : '')}
        aria-selected={this.props.selected ? 'true' : 'false'}
      >
        <a
          ref={(link) => { this.tabLink = link }}
          tabIndex={this.props.selected ? '0' : '-1'}
          ui-sref={this.props.children}
          alt={this.props.children}
          onClick={this.props.onClick}
          onKeyDown={this.props.onKeyDown}
          aria-controls={this.props.id}
          data-tab={this.props.id}
        >
          {this.props.children}
        </a>
      </li>
    )
  }
}

Tab.propTypes = {
  id: PropTypes.string,

  /**
   * Default selected status
   */
  selected: PropTypes.bool,

  /**
   * Title content of the tab
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,

  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,

  /**
   * This is triggered when the tab is selected.
   */
  onSelect: PropTypes.func.isRequired
}

Tab.defaultTypes = {
  selected: false
}

export default Tab