import React, { Component } from 'react'

import { connect } from 'react-redux'
import RubricsPeerReviewView from './rubrics-peer-review.view'
import rubricsActions from '../rubrics-service/rubrics-service.redux-actions'
import {getNewRubricObject} from '../util'

function mapStateToProps (state, props) {
  let obj = {
    peerReviewDetails: state.rubricService.peerReviewDetails,
    peerReviewUserList: state.rubricService.peerReviewUserList,
    rubric: state.rubricService.rubric
  }

  const nextProps = { ...props, ...obj }

  return nextProps
}

function mapDispatchToProps (dispatch, props) {
  var componentActions = {
    onInit: (props) => {
      dispatch(rubricsActions.fetchPeerReviewsList(props.rubricsServiceUrl, props.assignmentId,
        props.courseId, props.loggedInUser, props.peerRubricId, (data, err) => {
          if (err) {
            getNewRubricObject.notifyMessage(props, 'Error', 'Error', 'Rubric peer reviews list didn\'t load. Please try again.', [])
          }
        }, props.authType))
      dispatch(rubricsActions.fetchPeerReviewsSummaryCount(props.rubricsServiceUrl,
         props.assignmentId, props.courseId, props.peerRubricId, (data, err) => {
           if (err) {
             getNewRubricObject.notifyMessage(props, 'Error', 'Error', 'Rubric peer reviews list didn\'t load. Please try again.', [])
           }
         }, props.authType))
    }
  }

  return {
    ...props,
    ...componentActions
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RubricsPeerReviewView)
