import { rubricDropdownProperties } from '../../../rubricDropdownProperty'
export const categoryRegexGenerator = () => {
  var categoryArr = rubricDropdownProperties.filter(category => category.link !== '')
  var regexString = '\\b('
  categoryArr.map((categoryProperty, i) => {
    regexString = regexString + categoryProperty.value.trim().replace(/\s/g, '\\s+')
    if (i !== categoryArr.length - 1) {
      regexString = regexString + '|'
    } else {
      regexString = regexString + ')\\b'
    }
  })
  var regexPredefCategoryMatch = new RegExp(regexString, 'gi')
  return regexPredefCategoryMatch
}

// return the the category which will provide if its linkable or rubric category
export const getCategoryState = (regexPredefCategoryMatch, categoryTitle) => {
  var regexResultArr = []
  var regexResult
  var categoryState = {
    linkable : false,
    filterdRubricCategory: '',
    isCustomCategory: true
  }
  do {
    regexResult = regexPredefCategoryMatch.exec(categoryTitle)
    if (regexResult) {
      regexResultArr.push(regexResult[0])
    }
  } while (regexResult)
  if (regexResultArr.length === 1) {
    categoryState.linkable = true
    categoryState.isCustomCategory = regexResultArr[0] !== categoryTitle
    categoryState.filterdRubricCategory = rubricDropdownProperties.filter((check) => {
        // remove all white spaces and convert to uppercase
        // all white spaces are removed so development of ideas with spaces in between would affect
        // equal logic here
      return regexResultArr &&
      check.value.toUpperCase().replace(/\s+/g, '') === regexResultArr[0].toUpperCase().replace(/\s+/g, '')
    })
  } else if (regexResultArr.length > 1) {
    categoryState.isCustomCategory = true
  }
  return categoryState
}
