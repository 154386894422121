const initialState = {}

export default function rubricsPeerReviewComponentReducer (state = initialState, action) {
  switch (action.type) {
    case 'RUBRICS_PEER_REVIEW_SET_PEER_REVIEW_LIST':
      console.log(action.data)
      return {
        ...state
      }

    default:
      return state
  }
}
