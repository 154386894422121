import { PI_AUTH_HEADER, PI_SESSION_NAME, FORGEROCK_HEADER } from '../common/auth-headers-constants'
import Cookies from 'js-cookie'
import { validatePiSession } from '../../_util/pi'

const forgeRockToken = (authType) => {
  if (Cookies.get(authType)) {
    return Cookies.get(authType)
  } else if (localStorage.getItem(authType) !== null) {
    return localStorage.getItem(authType)
  } else {
    // error handler
    return null
  }
}

const piToken = (authType, callback) => {
  var mainToken = ''
  if (validatePiSession(callback)) {
    window.piSession.getToken((status, token) => {
      mainToken = token
    })
    // only if maintoken is empty and cookie is avaialable
    if (mainToken === '' && Cookies.get(PI_SESSION_NAME)) {
      mainToken = Cookies.get(PI_SESSION_NAME)
    }
  } else {
    if (mainToken === '' && Cookies.get(PI_SESSION_NAME)) {
      mainToken = Cookies.get(PI_SESSION_NAME)
    }
  }
  return mainToken
}
  /**
   * Return token depending on the token type
   * @param {*} authType
   */
export const getToken = (authType) => {
  switch (authType) {
    case FORGEROCK_HEADER:
      return forgeRockToken(authType)
    case PI_AUTH_HEADER:
      return piToken(authType)
    default:
      return null
  }
}
