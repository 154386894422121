import React, {Component} from 'react'
import PropTypes from 'prop-types'

class PeerComment extends Component {
    constructor(props) {
        super(props)
        this.clickOnCollapse = this.clickOnCollapse.bind(this)
        this.state = {
            collapsed: true
        }
    }

    clickOnCollapse() {
        if (this.state.collapsed) {
            this.setState({collapsed: false})
        } else {
            this.setState({collapsed: true})
        }
    }

    render() {
        return (
            <div className='rubric-peer-review-comment-item'>
                <div className='layout__row layout__align-space-between-center'>
                    {typeof this.props.onClickTitle === 'function' ?
                        <button className='pe-link--btn rubric-peer-review-comment-name rubric-peer-review-ellipsis'
                                onClick={this.props.onClickTitle.bind(this, this.props.index)}>{this.props.peerName}</button> :
                        <p className='rubric-peer-review-comment-name rubric-peer-review-ellipsis'>{this.props.peerName}</p>
                    }
                    {(this.props.levelIndex === 0 || this.props.levelIndex !== undefined) && this.props.totalLevels && this.props.quality &&
                    <div className='rubric-peer-review-comment-options layout__row layout__align-end-start'>
                        <p>{this.props.levelIndex} of {this.props.totalLevels}</p>
                        <svg aria-hidden='true' focusable='false' className='pe-icon--star-18'>
                            <use xlinkHref='#star-18'/>
                        </svg>
                        <p> {this.props.quality}</p>
                    </div>
                    }
                </div>
                {this.props.quality && this.props.rubricDescription &&
                <p className='rubric-peer-review-comment-description'>
                    <b>{`${this.props.levelIndex} ${this.props.quality}: `}</b>{this.props.rubricDescription}</p>}
                {this.props.peerComment &&
                <div className='rubric-peer-review-comment-heading layout__row'>
                    <span>Peer comments</span>
                    <button className='pe-icon--btn' onClick={this.clickOnCollapse}>
                        {
                            (!this.state.collapsed) ? (
                                <svg aria-hidden='true' focusable='false' className='pe-icon--dropdown-open-sm-18'>
                                    <use xlinkHref='#dropdown-open-sm-18'/>
                                </svg>) : (
                                <svg aria-hidden='true' focusable='false' className='pe-icon--dropdown-close-sm-18'>
                                    <use xlinkHref='#dropdown-close-sm-18'/>
                                </svg>)
                        }
                    </button>
                </div>
                }
                {!this.state.collapsed && (
                    <p className='rubric-peer-review-comment-text'>{this.props.peerComment}</p>)}
            </div>
        )
    }
}

PeerComment.propTypes = {}

PeerComment.defaultProps = {}

export default PeerComment
