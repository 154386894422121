/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2017 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

const UI_CONST = {
    USER_TYPE: {
        INSTRUCTOR: 'instructor',
        STUDENT: 'student'
    },
    REVIEW_TYPE: {
        PEER: 'PEER',
        INSTRUCTOR: 'INSTRUCTOR'
    },
    TEXT: {
        PEER: 'peer',
        PEERS: 'peers',
        OVERALL_PEER_COMMENT: 'Overall peer comment'
    },
    VIEW_MODE: {
        REVIEWS_RECEIVED: 'REVIEWS_RECEIVED',
        REVIEWS_GIVEN: 'REVIEWS_GIVEN'
    },
    ORDER_BY: {
        BY_CRITERIA: 'BY_CRITERIA',
        BY_USER: 'BY_USER'
    },
    MESSAGES:{
        NO_PEER_RUBRIC : 'No Peer Rubrics for this assignment.',
        NO_PEER_REVIEWS : 'No peer reviews yet, check back later.'
    }
}

export default UI_CONST
