export const rubricDropdownProperties = [
    {
        value: 'Custom category name',
        link: '',
        display: 'Custom category name',
        isDisabled: false,
        seperator: true
    },
    {
        value: 'Focus and Coherence',
        link: 'https://media.pearsoncmg.com/long/pw_wsol/focus_coherence.html',
        display: 'Focus and Coherence',
        isDisabled: false
    },
    {
        value: 'Conventions',
        link: 'https://media.pearsoncmg.com/long/pw_wsol/conventions.html',
        display: 'Conventions',
        isDisabled: false
    },
    {
        value: 'Development of ideas',
        link: 'https://media.pearsoncmg.com/long/pw_wsol/Ideas.html',
        display: 'Development of ideas',
        isDisabled: false
    },
    {
        value: 'Organization',
        link: ' https://media.pearsoncmg.com/long/pw_wsol/organization.html',
        display: 'Organization',
        isDisabled: false
    },
    {
        value: 'Voice',
        link: 'https://media.pearsoncmg.com/long/pw_wsol/voice.html',
        display: 'Voice',
        isDisabled: false
    }
]

export default { rubricDropdownProperties }
