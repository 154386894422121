import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {formatDateInMMddYYformat} from '../../common/helper'

class AccordianItem extends Component {
    constructor(props) {
        super(props)

    }

    render() {
        return (
            <div className='rubric-peer-review-accordian-item layout__column' key={this.props.index}>
                <div className='layout__row layout__align-space-between-start'>
                    <div
                        className='rubric-peer-review-accordian-title layout__row layout__align-start-center '>
                        <button className='pe-icon--btn'
                                onClick={this.props.clickOnCollapse.bind(this, this.props.index)}>
                            {
                                (!this.props.collapsed && this.props.collapsedIndex === this.props.index) ? (
                                    <svg aria-hidden='true' focusable='false'
                                         className='pe-icon--dropdown-open-sm-24'>
                                        <use xlinkHref='#dropdown-open-sm-24'/>
                                    </svg>) : (
                                    <svg aria-hidden='true' focusable='false'
                                         className='pe-icon--dropdown-close-sm-24'>
                                        <use xlinkHref='#dropdown-close-sm-24'/>
                                    </svg>)
                            }

                        </button>
                        <span className="rubric-peer-review-ellipsis">{this.props.title}</span></div>
                    {this.props.average && this.props.total && this.props.quality &&
                    <div className='rubric-peer-review-accordian-options layout__row layout__align-end-center'>
                        <p>{this.props.average} of {this.props.total}</p>
                        <svg aria-hidden='true' focusable='false' className='pe-icon--star-18'>
                            <use xlinkHref='#star-18'/>
                        </svg>
                        <p>{this.props.quality}</p></div>}
                    {this.props.submittedTime &&
                    <p>{formatDateInMMddYYformat(this.props.submittedTime)}</p>
                    }
                </div>
                {!this.props.collapsed && this.props.collapsedIndex === this.props.index &&
                <div className='rubric-peer-review-accordian-content'>
                    {this.props.children}
                </div>
                }
            </div>
        )
    }
}

AccordianItem.propTypes = {}

AccordianItem.defaultProps = {
    title: '',
    average: '',
    total: '',
    quality: '',
    submittedTime: ''
}

export default AccordianItem
