/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2017 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import axios from 'axios'
const piAuthHeader = 'X-Authorization'
const forgeRockHeader = 'PearsonSSOSession'
/**
 * Generate UUID
 * @returns {string}
 */
export function generateUUID () {
  let d = new Date().getTime()
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16)
  })
}

/**
 * Get New Axios Configuration Instance.
 * @param endPoint
 * @param token
 * @param correlationId
 */
export function getAxiosConfig (endPoint, tokenType, token, correlationId) {
  let axiosIns = axios.create()

  axiosIns.defaults.baseURL = endPoint

  axiosIns.interceptors.request.use((config) => {
    config.headers.common['Content-Type'] = 'application/json'
    config.headers[tokenType === piAuthHeader ? piAuthHeader : forgeRockHeader] = token
    config.headers['correlation-id'] = correlationId

    return config
  })

  return axiosIns
}

/**
 * format date in MM/DD/YY HH:mm AM/PM format
 * @param date
 * @returns {string}
 */
export function formatDateInMMddYYformat (date) {
  if (date) {
    try {
      let arr = (date.split('T')[0]).split('-')
      let timeString = (date.split('T')[1]).split('+')[0].split(':')
      let H = timeString[0]
      let h = H % 12 || 12
      let ampm = (H < 12 || H === 24) ? 'AM' : 'PM'
      timeString = `${h}:${timeString[1]} ${ampm}`
      return `${arr[1]}/${arr[2]}/${arr[0].substr(2, 4)} ${timeString}`
    } catch (e) {
      console.log(e)
      return ''
    }
  } else {
    return ''
  }
}
