/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2017 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/* eslint-disable react/no-set-state */
import React from 'react'
import PropTypes from 'prop-types'

class CommentBox extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      comment: this.props.commentData.comment,
      isSaveButtonDisabled: true,
      isGradeCommentExpanded: this.props.commentData.comment,
      isGradeCommentEditable: !this.props.commentData.comment
    }
    this.bindInteractions()
  }

  /**
   * componentWillReceiveProps react life cycle event
   * @param nextProps
   */
  componentWillReceiveProps (nextProps) {
    if (this.props.commentData.studentId !== nextProps.commentData.studentId || (this.props.commentData.comment !== nextProps.commentData.comment)) {
      this.setState({
        comment: nextProps.commentData.comment,
        isSaveButtonDisabled: true,
        isGradeCommentExpanded: nextProps.commentData.comment,
        isGradeCommentEditable: !nextProps.commentData.comment
      })
    }
  }

  /**
   * bind functions
   */
  bindInteractions () {
    this.onSaveComment = this.onSaveComment.bind(this)
    this.onChangeComment = this.onChangeComment.bind(this)
    this.expandGradeComment = this.expandGradeComment.bind(this)
    this.editGradeComment = this.editGradeComment.bind(this)
    this.onCancel = this.onCancel.bind(this)
  }

  /**
   * on click save overall score to
   * persist score
   */
  onChangeComment (event) {
    let isSaveButtonDisabled = (event.currentTarget.value === '' || (event.currentTarget.value.match(/^\s+$/) !== null))
    let cleanedStr = null
    //Remove special characters from the text
    const PATTERN = /[^a-zA-Z0-9\t\n ,./<>?;:"'`~!@#$%^&*()\[\]\{\}_+=|\\-]/gu

    if ((event.target.value !== null) || (event.target.value !== '')) {
      cleanedStr = event.target.value.replace(PATTERN, '')
    }

    this.setState({
      comment: cleanedStr,
      isSaveButtonDisabled: isSaveButtonDisabled,
      isGradeCommentEditable: true,
      isGradeCommentExpanded: false
    })
  }

  /**
   * on click save comment
   */
  onSaveComment () {
    this.props.actions.onSave(this.state.comment)
    this.editGradeComment()
    this.setState({
      isSaveButtonDisabled: true
    })
  }

  /**
   * on cancel button click
   */
  onCancel () {
    this.editGradeComment()
    this.setState({
      isGradeCommentExpanded: true,
      comment: this.props.commentData.comment
    })
  }

  /**
   * expand and collapse grade comment
   */
  expandGradeComment () {
    this.setState({
      isGradeCommentExpanded: !this.state.isGradeCommentExpanded
    })
  }

  /**
   * edit grade comment
   */
  editGradeComment () {
    this.setState({
      isGradeCommentEditable: !this.state.isGradeCommentEditable
    })
  }

  render () {
    let htmlContent = null
    if ((this.state.comment === '' || this.state.isGradeCommentEditable)) {
      htmlContent = (
        <div className="layout__column layout__align-start-start peer-comment-box">
          <label className="pe-label--bold" forHtml="gradingCommText">Comment</label>
          <textarea name="largetext"
                    value={this.state.comment}
                    rows="4"
                    className="pe-multiLineText"
                    id="gradingCommText"
                    onChange={this.onChangeComment}
                    aria-describedby="gradeCommentInfo"
          />
          {this.state.comment !== '' &&
          <div className="peer-comment-box-button-section">
            <button onClick={this.onSaveComment}
                    className="pe-btn__primary pull-right"
                    disabled={this.state.isSaveButtonDisabled}>Save
            </button>
            <button onClick={this.onCancel} className="pe-btn pull-right marginRight">Cancel
            </button>
          </div>
          }
        </div>
      )
    }
    else if (this.state.isGradeCommentExpanded) {
      htmlContent = (
        <div className="peer-comment-box">
          <button
              type="button"
              aria-label="collapse Overall peer comments"
              className="pe-icon--btn"
              onClick={this.expandGradeComment}>
            <b>Overall peer comments</b>
            <svg role="img"
                 focusable="false"
                 className="pe-icon--dropdown-open-sm-18">
              <use xlinkHref="#dropdown-open-sm-18"></use>
            </svg>
          </button>
          <p className="peer-comment-box-display-text">{this.state.comment}</p>
          <div className="layout__row layout__align-end-center">
            <button
                type="button"
                aria-label="edit Overall peer comments"
                className="pe-icon--btn"
                onClick={this.editGradeComment}>
              <svg role="img"
                   focusable="false"
                   className="pe-icon--edit-18">
                <use xlinkHref="#edit-18"></use>
              </svg>
            </button>
          </div>
        </div>
      )
    } else {
      htmlContent = (
        <div className="peer-comment-box">
          <button
              type="button"
              aria-label="expand Overall peer comments"
              className="pe-icon--btn"
              onClick={this.expandGradeComment}>
            <b>Overall peer comments</b>
            <svg role="img"
                 focusable="false"
                 className="pe-icon--dropdown-close-sm-18">
              <use xlinkHref="#dropdown-close-sm-18"></use>
            </svg>
          </button>
        </div>
      )
    }
    return htmlContent
  }
}

CommentBox.propTypes = {
  commentData: PropTypes.object,
  actions: PropTypes.object
}

export default CommentBox
