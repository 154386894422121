import React, { Component } from 'react'
import PropTypes from 'prop-types'
import '../../browser-compatibility'

import { Provider } from 'react-redux'
import store from '../rubrics.redux-store'

import RubricsPeerReviewView from './rubrics-peer-review.container'

/**
 * ### Import
 * ```js
 * import Rubrics from '@pearson-learning-apps-shared-components/webcomponents'
 * const PeerReview = Rubrics.PeerReview
 * ```
 *
 * #### Overriding with a custom class <strong> (Recommended) </strong>
 * ```html
 * <PeerReview/>
 * ```
 *
 * #### Overriding root element styles as a native jsx element
 * ```js
 * <PeerReview/>
 * ```
 *
 */
const RubricsPeerReview = (props) => {
    var validProps = Object.assign({}, props)
    for (const i in validProps) {
      if (validProps[i] === undefined) {
        delete validProps[i]
      }
    }
    return <Provider store={store}>
      <RubricsPeerReviewView
        {...validProps}
            />
    </Provider>
}

RubricsPeerReview.propTypes = {
    /**
     * This ID is used to sync this with the mapping select rubric component <br/>
     * This is default to empty string ''
     */
  componentId: PropTypes.string.isRequired,

    /**
     * The Rubrics Service API Url.
     */
  rubricsServiceUrl: PropTypes.string.isRequired,

    /**
     * All the alert messages are to be delivered via this callback.<br/>
     * This will be directed to UXF alerts if the callback is not defined <br/>
     * <strong>Message types:</strong> Success, Error, Information, Warning, Log
     *
     * @param {String} messageType
     * @param {String} messageTitle
     * @param {String} message
     * @param {Object} actions
     */
  onMessage: PropTypes.func,

    /**
     * All the confirmations are to be delivered via this callback.<br/>
     * This will directed to window.confirmations if the callback is not defined.<br/>
     * <strong>Confirmation types:</strong> Success, Error, Information, Warning
     *
     * @param {String} confirmationType
     * @param {String} confirmationTitle
     * @param {String} message
     * @param {Object} actions
     */
  onConfirm: PropTypes.func,

    /**
     * This is similar to `className` prop in native jsx elements
     */
  className: PropTypes.string,

    /**
     * This is similar to `style` prop in native jsx elements
     */
  style: PropTypes.object,

    /**
     * The rubrics will be filtered and categorized only based on this property.
     * This must be consistent inside the project
     *
     * - <strong>MSNG</strong> Media share
     * - <strong>WSOL</strong> Writing Solutions
     */
  tenantId: PropTypes.string,

    /**
     * Review Peer details Object
     */
  reviewPeersList: PropTypes.object,

    /**
     * number of peer Reviews count
     */
  numberOfPeerReviews: PropTypes.number

}

RubricsPeerReview.defaultProps = {
  componentId: ''
}

export default RubricsPeerReview
